"use client";

import {
	handleAddToCart,
	handleUpdateCartLineItem,
} from "@/components/PDP/ProductForm/_actions/addToCart";
import QuantityChanger from "@/components/QuantityChanger/QuantityChanger";
import StockAvailability from "@/components/StockAvailability/StockAvailability";
import AddToWishlistButtonComplex from "@/components/UI/AddToWishlistCTA/AddToWishlistButtonComplex";
import AddToWishlistCTA from "@/components/UI/AddToWishlistCTA/AddToWishlistCTA";
import Button from "@/components/UI/Button/Button";
import FormattedPrice from "@/components/UI/FormattedPrice/FormattedPrice";
import {
	getCartDatalayerDetails,
	getItemListData,
	useDataLayerContext,
} from "@/datalayer/DataLayerContextProvider";
import checkUserHasRolePermission from "@/hooks/checkUserHasRolePermission";
import mapProductOptions from "@/hooks/mapProductOptions";
import useStoreKeys from "@/hooks/useStoreKeys";
import { useI18n } from "@/i18n/client";
import { ProductAddToCart, ProductData } from "@/lib/5874/types";
import { useSessionContext } from "@/lib/auth/SessionContextProvider";
import { WishlistDetailsData } from "@/lib/bigcommerce-b2b/types";
import { ProductAvailability } from "@/lib/bigcommerce/enums";
import { useCartContext } from "@/providers/CartContextProvider";
import { getLineItemForProduct } from "@/utils/cart-utils";
import Link from "next/link";
import { useMemo, useState } from "react";
import styles from "./ProductOption.module.scss";
import { useBigCommerceStoreContext } from "@/providers/BigCommerceStoreProvider";
import { userHasRequiredCertifications } from "@/hooks/userHasRequiredCertifications";

export interface ProductOptionData {
	product: ProductData;
	handleRemovedFromCart?: () => void;
	handleParentModal?: () => void;
	wishlistId?: number;
	handleAddToWishlist?: (
		productId: number,
	) => Promise<WishlistDetailsData | undefined>;
}

const ProductOption: React.FC<ProductOptionData> = ({
	product,
	handleRemovedFromCart,
	wishlistId,
	handleAddToWishlist,
}) => {
	const { session } = useSessionContext();
	const { store, lang } = useStoreKeys();
	const { cart, setCart, isModifyingCart, setIsModifyingCart } =
		useCartContext();
	const t = useI18n();
	const [isAddingToCart, setIsAddingToCart] = useState(false);
	const lineItem = cart ? getLineItemForProduct(cart, product) : undefined;

	if (!session || !product) return null;

	// Datalayer events
	const { addToCart } = useDataLayerContext();
	const { itemListId, itemListName } = getItemListData(product);
	const cartDetails = getCartDatalayerDetails(cart);
	const datalayerProduct = {
		product: product,
		quantity: 1,
		variantId: product.id,
	};

	const productSpecifications = useMemo(() => {
		return mapProductOptions([product]);
	}, [product]);

	const addProductToCart = async (data: ProductAddToCart, lineItem?: any) => {
		setIsAddingToCart(true);
		setIsModifyingCart(true);

		const res = lineItem
			? await handleUpdateCartLineItem(data, lineItem)
			: await handleAddToCart([data]);

		// Handle scenarios where the res has no data
		if (!res.data) {
			handleRemovedFromCart && handleRemovedFromCart();
			setCart(res.data);
		}

		// Process the cart update on success
		if (res.status === "success") {
			setCart(res.data);
			setIsModifyingCart(false);
			setIsAddingToCart(false);
		}

		setIsModifyingCart(false);
		setIsAddingToCart(false);
	};

	// Product Certification Restrictions
	const { companyCourseCertification } = useBigCommerceStoreContext();
	const hasRequiredCertifications = userHasRequiredCertifications(
		store,
		product,
		companyCourseCertification,
	);

	const showAddToCart =
		product.inventory.isInStock && hasRequiredCertifications;

	return (
		<div key={product.id} className={styles.product}>
			<Link href={`/${store}/${lang}/${product.path}`} className={styles.name}>
				{product.name}
			</Link>
			<span className={styles.sku}>{product.sku}</span>
			<div className={styles.specs}>
				{productSpecifications?.map((spec) => (
					<div key={spec.label} className={styles.spec}>
						<span className={styles.label}>{spec.label}:</span>
						<span className={styles.value}>{spec.options[0].label}</span>
					</div>
				))}
			</div>
			<span className={styles.price}>
				<FormattedPrice price={product?.pricesExTax?.basePrice?.value} />
			</span>
			{showAddToCart ? (
				<div className={styles.actions}>
					{!wishlistId ? (
						<>
							{lineItem !== undefined ? (
								<QuantityChanger
									minValue={0}
									value={lineItem.quantity}
									cart={cart}
									onChange={(quantity) => {
										addProductToCart(
											{
												id: product.id,
												quantity,
											},
											lineItem,
										);
									}}
									debounceTime={600}
								/>
							) : (
								<Button
									as='button'
									type='button'
									className={styles.cartButton}
									disabled={
										isModifyingCart ||
										product.isNotPurchasable ||
										!showAddToCart
									}
									isLoading={isAddingToCart}
									onClick={(e) => {
										e.stopPropagation();
										addToCart({
											products: [datalayerProduct],
											itemListId: itemListId,
											itemListName: itemListName,
											cart: cartDetails,
										});
										addProductToCart(
											{
												id: product.id,
												quantity: 1,
											},
											null,
										);
									}}
								>
									{showAddToCart ? t("cart.addToCart") : t("cart.outOfStock")}
								</Button>
							)}
							{checkUserHasRolePermission("update_shopping_list_item") && (
								<AddToWishlistButtonComplex
									userId={session.userId}
									product={product}
								/>
							)}
						</>
					) : (
						""
					)}
					{wishlistId && (
						<AddToWishlistCTA
							product={product}
							userId={session.userId}
							wishlistId={wishlistId}
							customClass={wishlistId ? styles.wishlistButton : ""}
							handleAddToWishlist={handleAddToWishlist}
						/>
					)}
				</div>
			) : null}
			<StockAvailability product={product} className={styles.stock} />
		</div>
	);
};

export default ProductOption;
