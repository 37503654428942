"use client";

import clsx from "clsx";
import { FC, useMemo } from "react";
import { Category, ProductData } from "@/lib/5874/types";
import { useSessionContext } from "@/lib/auth/SessionContextProvider";
import { useCartContext } from "@/providers/CartContextProvider";
import { useDataLayerContext } from "@/datalayer/DataLayerContextProvider";
import useIntelliSuggestTracking from "@/hooks/useIntelliSuggestTracking";
import { ProductContextProvider } from "@/providers/ProductContextProvider";
import { getLineItemForProduct } from "@/utils/cart-utils";
import CommonProductDetails from "./CommonProductDetails/CommonProductDetails";
import StockAvailability from "../StockAvailability/StockAvailability";
import Pricing from "./Pricing/Pricing";
import styles from "./ProductCard.module.scss";
import { ProductForm } from "../PDP/ProductForm/ProductForm";
import { userHasRequiredCertifications } from "@/hooks/userHasRequiredCertifications";
import useStoreKeys from "@/hooks/useStoreKeys";
import { useBigCommerceStoreContext } from "@/providers/BigCommerceStoreProvider";

interface ProductCardProps {
	product: ProductData;
	showSimpleView?: boolean;
	className?: string;
	category?: Category;
	itemListId?: string;
	itemListName?: string;
	currencyCode: string;
	isInRecommendationCarousel?: boolean;
}

const ProductCard: FC<ProductCardProps> = ({
	product,
	showSimpleView = false,
	className,
	category,
	currencyCode,
	itemListId,
	itemListName,
	isInRecommendationCarousel = false,
}) => {
	const { session } = useSessionContext();
	const { cart } = useCartContext();
	const { store, lang } = useStoreKeys();
	const isCustomer = session !== null;
	const inCart = getLineItemForProduct(cart, product);
	const { selectItem } = useDataLayerContext();

	// Product Certification Restrictions
	const { companyCourseCertification } = useBigCommerceStoreContext();
	const hasRequiredCertifications = userHasRequiredCertifications(
		store,
		product,
		companyCourseCertification,
	);
	const showAddToCart =
		isCustomer &&
		hasRequiredCertifications &&
		!showSimpleView &&
		product.inventory.isInStock;

	// Intellisuggest
	const { productClick } = useIntelliSuggestTracking();

	const kitPrice = useMemo(() => {
		if (!Array.isArray(product?.kitProducts)) return 0;

		return product.kitProducts.reduce((acc, product) => {
			if (!product?.pricesExTax?.basePrice?.value || !product.kitQuantity)
				return 0;

			const price =
				product?.pricesExTax?.basePrice?.value * product.kitQuantity;
			return acc + price;
		}, 0);
	}, [product]);

	const handleClick = () => {
		if (!isInRecommendationCarousel) {
			productClick(product);
		}
		selectItem(
			product,
			product.id,
			itemListId ?? String(category?.id),
			itemListName ?? category?.name,
			currencyCode,
		);
	};

	return (
		<ProductContextProvider product={product}>
			<article
				key={product.id}
				onClick={handleClick}
				onKeyDown={(e) => e.key === "Enter" && handleClick()}
				className={clsx([
					styles.card,
					inCart !== undefined && styles.inCart,
					className,
				])}
			>
				<div className={styles.inner}>
					<CommonProductDetails
						isCustomer={isCustomer}
						showSimpleView={showSimpleView}
					/>
					<div className={styles.pricing}>
						<Pricing
							linkClassName={styles.loginLink}
							staticPrice={
								product.productClass?.toLowerCase() === "kit"
									? kitPrice
									: undefined
							}
						/>
						{isCustomer && (
							<>
								<ProductForm showAddToCart={showAddToCart} />
								<StockAvailability product={product} />
							</>
						)}
					</div>
				</div>
			</article>
		</ProductContextProvider>
	);
};

export default ProductCard;
