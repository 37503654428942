"use client";

import Modal from "@/components/Modal/Modal";
import Button from "@/components/UI/Button/Button";
import { useI18n } from "@/i18n/client";
import { ProductData } from "@/lib/5874/types";
import { WishlistDetailsData } from "@/lib/bigcommerce-b2b/types";
import { useCartContext } from "@/providers/CartContextProvider";
import clsx from "clsx";
import { FC, ReactNode, useEffect, useState } from "react";
import styles from "./ChooseOptionsCTA.module.scss";
import ProductOption from "./ProductOption/ProductOption";

interface ChooseOptionsCTAProps {
	productOptions: ProductData[];
	triggerButton?: ReactNode;
	handleRemovedFromCart?: () => void;
	buttonStyle?:
		| "primary"
		| "secondary"
		| "tertiary"
		| "rounded"
		| "empty"
		| undefined;
	buttonClassName?: string;
	buttonText?: string;
	wishlistId?: number;
	handleAddToWishlist?: (
		productId: number,
	) => Promise<WishlistDetailsData | undefined>;
}

const ChooseOptionsCTA: FC<ChooseOptionsCTAProps> = ({
	productOptions,
	handleRemovedFromCart,
	buttonStyle = "primary",
	buttonClassName,
	buttonText,
	wishlistId,
	handleAddToWishlist,
	triggerButton,
}) => {
	const { cart } = useCartContext();
	const [isOpen, setIsOpen] = useState(false);
	const [hideModal, setHideModal] = useState(false);
	const [hasChildrenInCart, setHasChildrenInCart] = useState(false);
	const t = useI18n();

	if (!productOptions) return null;

	useEffect(() => {
		if (!cart) setHasChildrenInCart(false);
		if (cart?.line_items.physical_items.length) {
			// check if any of the children are in the cart
			const hasChildrenInCart = productOptions?.some((product) =>
				cart.line_items.physical_items.some((item) => item.sku === product.sku),
			);
			setHasChildrenInCart(hasChildrenInCart);
		}
	}, [cart]);

	return (
		<>
			{triggerButton || (
				<Button
					as='button'
					type='button'
					onClick={() => setIsOpen((state) => !state)}
					className={buttonClassName || styles.button}
					variant={buttonStyle}
				>
					{buttonText || (
						<>
							{(!hasChildrenInCart || wishlistId) && t("product.chooseOptions")}
							{hasChildrenInCart && !wishlistId && t("product.updateOptions")}
						</>
					)}
				</Button>
			)}
			<Modal
				title={t("product.chooseOptions")}
				toggleModal={() => setIsOpen((state) => !state)}
				isOpen={isOpen}
				contentClassName={clsx([
					styles.modal,
					hideModal ? styles.hideModal : "",
				])}
			>
				<div className={styles.inner}>
					<div className={styles.grid}>
						{productOptions?.map((product) => {
							if (product.id) {
								return (
									<ProductOption
										key={product.id}
										product={product}
										handleRemovedFromCart={handleRemovedFromCart}
										handleParentModal={() => setHideModal(!hideModal)}
										handleAddToWishlist={handleAddToWishlist}
										wishlistId={wishlistId}
									/>
								);
							}
						})}
					</div>
				</div>
			</Modal>
		</>
	);
};

export default ChooseOptionsCTA;
